*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.form-input, .form-textarea, .form-select, .form-multiselect {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-input:focus, .form-textarea:focus, .form-select:focus, .form-multiselect:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

.form-select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

.sr-only {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.inset-0 {
  inset: 0 !important;
}

.inset-y-0 {
  top: 0 !important;
  bottom: 0 !important;
}

.inset-y-12 {
  top: 3rem !important;
  bottom: 3rem !important;
}

.left-0 {
  left: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.z-50 {
  z-index: 50 !important;
}

.z-40 {
  z-index: 40 !important;
}

.z-10 {
  z-index: 10 !important;
}

.float-right {
  float: right !important;
}

.m-0 {
  margin: 0 !important;
}

.m-2 {
  margin: .5rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.-mx-3 {
  margin-left: -.75rem !important;
  margin-right: -.75rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.mt-36 {
  margin-top: 9rem !important;
}

.-ml-36 {
  margin-left: -9rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.-mt-2 {
  margin-top: -.5rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.-mb-3 {
  margin-bottom: -.75rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.-mt-20 {
  margin-top: -5rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.-mt-36 {
  margin-top: -9rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.-mt-1 {
  margin-top: -.25rem !important;
}

.block {
  display: block !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.table {
  display: table !important;
}

.hidden {
  display: none !important;
}

.h-screen {
  height: 100vh !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-3 {
  height: .75rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-\[60vh\] {
  height: 60vh !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.w-auto {
  width: auto !important;
}

.w-full {
  width: 100% !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.w-3 {
  width: .75rem !important;
}

.w-max {
  width: max-content !important;
}

.w-80 {
  width: 20rem !important;
}

.w-24 {
  width: 6rem !important;
}

.w-1\/2, .w-2\/4 {
  width: 50% !important;
}

.w-3\/5 {
  width: 60% !important;
}

.min-w-max {
  min-width: max-content !important;
}

.max-w-3xl {
  max-width: 48rem !important;
}

.max-w-lg {
  max-width: 32rem !important;
}

.flex-auto {
  flex: auto !important;
}

.table-auto {
  table-layout: auto !important;
}

.border-collapse {
  border-collapse: collapse !important;
}

.rotate-45 {
  --tw-rotate: 45deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.list-none {
  list-style-type: none !important;
}

.appearance-none {
  appearance: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-center {
  align-items: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity)) !important;
}

.self-end {
  align-self: flex-end !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.truncate {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.rounded-lg {
  border-radius: .5rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-none {
  border-radius: 0 !important;
}

.rounded-md {
  border-radius: .375rem !important;
}

.rounded-xl {
  border-radius: .75rem !important;
}

.rounded-t {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-b {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-l-lg {
  border-top-left-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.rounded-r-lg {
  border-top-right-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.border {
  border-width: 1px !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-b {
  border-bottom-width: 1px !important;
}

.border-t {
  border-top-width: 1px !important;
}

.border-l {
  border-left-width: 1px !important;
}

.border-r {
  border-right-width: 1px !important;
}

.border-l-2 {
  border-left-width: 2px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-slate-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.border-gray-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.border-green-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.border-gray-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.border-blue-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.border-slate-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.border-black\/5 {
  border-color: #0000000d !important;
}

.border-transparent {
  border-color: #0000 !important;
}

.border-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.border-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.bg-indigo-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity)) !important;
}

.bg-pink-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.bg-emerald-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity)) !important;
}

.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}

.bg-slate-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity)) !important;
}

.bg-slate-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity)) !important;
}

.bg-slate-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity)) !important;
}

.bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

.bg-gray-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity)) !important;
}

.bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity)) !important;
}

.bg-slate-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}

.bg-green-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important;
}

.bg-red-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity)) !important;
}

.bg-pink-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity)) !important;
}

.bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
}

.bg-orange-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity)) !important;
}

.bg-green-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity)) !important;
}

.bg-gray-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity)) !important;
}

.bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity)) !important;
}

.bg-slate-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity)) !important;
}

.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.bg-green-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity)) !important;
}

.bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important;
}

.bg-teal-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity)) !important;
}

.bg-blue-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity)) !important;
}

.bg-green-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity)) !important;
}

.bg-red-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity)) !important;
}

.bg-slate-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity)) !important;
}

.bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}

.bg-red-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}

.bg-clip-padding {
  background-clip: padding-box !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-2\.5 {
  padding: .625rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.py-2\.5 {
  padding-top: .625rem !important;
  padding-bottom: .625rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-1\.5 {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pl-3 {
  padding-left: .75rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.pr-10 {
  padding-right: 2.5rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-xs {
  font-size: .75rem !important;
  line-height: 1rem !important;
}

.text-8xl {
  font-size: 6rem !important;
  line-height: 1 !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-5xl {
  font-size: 3rem !important;
  line-height: 1 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-relaxed {
  line-height: 1.625 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.tracking-wide {
  letter-spacing: .025em !important;
}

.text-blue-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(29 78 216 / var(--tw-text-opacity)) !important;
}

.text-red-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(185 28 28 / var(--tw-text-opacity)) !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-slate-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity)) !important;
}

.text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
}

.text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
}

.text-pink-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(190 24 93 / var(--tw-text-opacity)) !important;
}

.text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(31 41 55 / var(--tw-text-opacity)) !important;
}

.text-slate-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(30 41 59 / var(--tw-text-opacity)) !important;
}

.text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}

.text-green-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(22 163 74 / var(--tw-text-opacity)) !important;
}

.text-slate-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(71 85 105 / var(--tw-text-opacity)) !important;
}

.text-slate-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(51 65 85 / var(--tw-text-opacity)) !important;
}

.text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}

.underline {
  text-decoration-line: underline !important;
}

.opacity-5 {
  opacity: .05 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.outline-none {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.transition-all {
  transition-property: all !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.duration-150 {
  transition-duration: .15s !important;
}

.ease-linear {
  transition-timing-function: linear !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-slate-300:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-slate-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.focus\:border-blue-600:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.focus\:border-red-300:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.focus\:border-red-700:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1 !important;
  color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
}

.focus\:outline-none:focus {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-200:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity)) !important;
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: .5 !important;
}

.active\:bg-pink-600:active {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity)) !important;
}

.active\:bg-emerald-600:active {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity)) !important;
}

.active\:bg-red-700:active {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity)) !important;
}

.active\:bg-red-600:active {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}

.disabled\:bg-gray-100:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.disabled\:opacity-25:disabled {
  opacity: .25 !important;
}

.group:hover .group-hover\:flex {
  display: flex !important;
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-700 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
  }

  .dark\:border-white\/5 {
    border-color: #ffffff0d !important;
  }

  .dark\:text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1 !important;
    color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
  }

  .dark\:text-gray-200 {
    --tw-text-opacity: 1 !important;
    color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
  }

  .dark\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity)) !important;
  }

  .dark\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity)) !important;
  }

  .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }

  .dark\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity)) !important;
  }
}

@media (min-width: 640px) {
  .sm\:h-9 {
    height: 2.25rem !important;
  }

  .sm\:max-w-md {
    max-width: 28rem !important;
  }

  .sm\:justify-start {
    justify-content: flex-start !important;
  }

  .sm\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:pt-0 {
    padding-top: 0 !important;
  }

  .sm\:text-sm {
    font-size: .875rem !important;
    line-height: 1.25rem !important;
  }
}

@media (min-width: 768px) {
  .md\:order-2 {
    order: 2 !important;
  }

  .md\:order-1 {
    order: 1 !important;
  }

  .md\:mr-0 {
    margin-right: 0 !important;
  }

  .md\:mt-0 {
    margin-top: 0 !important;
  }

  .md\:mb-0 {
    margin-bottom: 0 !important;
  }

  .md\:flex {
    display: flex !important;
  }

  .md\:hidden {
    display: none !important;
  }

  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-6\/12 {
    width: 50% !important;
  }

  .md\:w-2\/3 {
    width: 66.6667% !important;
  }

  .md\:w-3\/12 {
    width: 25% !important;
  }

  .md\:w-8\/12 {
    width: 66.6667% !important;
  }

  .md\:w-7\/12 {
    width: 58.3333% !important;
  }

  .md\:w-5\/12 {
    width: 41.6667% !important;
  }

  .md\:w-4\/12 {
    width: 33.3333% !important;
  }

  .md\:w-2\/12 {
    width: 16.6667% !important;
  }

  .md\:w-10\/12 {
    width: 83.3333% !important;
  }

  .md\:flex-row {
    flex-direction: row !important;
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:border-0 {
    border-width: 0 !important;
  }

  .md\:p-0 {
    padding: 0 !important;
  }

  .md\:text-sm {
    font-size: .875rem !important;
    line-height: 1.25rem !important;
  }

  .md\:font-medium {
    font-weight: 500 !important;
  }

  .md\:hover\:bg-transparent:hover {
    background-color: #0000 !important;
  }

  .md\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(29 78 216 / var(--tw-text-opacity)) !important;
  }

  @media (prefers-color-scheme: dark) {
    .md\:dark\:hover\:bg-transparent:hover {
      background-color: #0000 !important;
    }

    .md\:dark\:hover\:text-white:hover {
      --tw-text-opacity: 1 !important;
      color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
    }
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translateZ(var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-center, .Toastify__toast-container--top-left, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    left: auto;
    right: 0;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--colored.Toastify__toast--default, .Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:focus, .Toastify__close-button:hover {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  transform-origin: 100%;
  left: auto;
  right: 0;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--bottom-left, .Toastify__bounce-enter--top-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--bottom-right, .Toastify__bounce-enter--top-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--bottom-left, .Toastify__bounce-exit--top-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--bottom-right, .Toastify__bounce-exit--top-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  0% {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInLeft {
  0% {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInUp {
  0% {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInDown {
  0% {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideOutRight {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--bottom-left, .Toastify__slide-enter--top-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--bottom-right, .Toastify__slide-enter--top-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--bottom-left, .Toastify__slide-exit--top-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--bottom-right, .Toastify__slide-exit--top-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid #0000;
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  font-family: Helvetica Neue, helvetica, arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: .3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 2px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #216ba5;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #2579ba;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #216ba580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: .3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

/*# sourceMappingURL=index.5d34fd92.css.map */
